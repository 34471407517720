<template>
    <div class="container-list">
        <div v-for="(item,index) of listData" :key="index" :style="{backgroundColor:bgColor}" class="container-item" @click="toNewsInfo(item.id,type)">
            <div class="img" v-if="item.coverImage != null && item.coverImage != undefined && item.coverImage != '' && item.coverImage !='null' && item.coverImage != 'undefined'"><img :src="configUrl+item.coverImage"></div>
            <div class="img" v-else ><img src="../imgs/news/noImg.png"></div>
            <span>{{item.title}}</span>
            <p>{{item.description}}</p>
            <span>{{item.createTime.split(' ')[0]}}</span>
        </div>
        <div class="container-item" style="height: 0;visibility:hidden"></div>
    </div>
</template>
<script>
import apiConfig from "../api/config"
export default {
    data(){
        return{
            configUrl:'',
            screenState:false
        }
    },
    props:{
        listData:[],
        type:Number,
        bgColor:String
    },
    created(){
        // 初始化获取浏览器窗口大小
        let screenWidth = document.body.clientWidth;
        // 根据浏览器窗口大小判断是否显示页面内的部分内容
        if(screenWidth<1200){
            this.screenState=false;
        }else{
            this.screenState=true;
        }
        this.configUrl = apiConfig.url
    },
    methods:{
        // 跳转新闻详情页
        toNewsInfo(id,type){
            let query = {
                id : id,
                type : type
            }
            this.$router.push({path:'/news/newsInfo',query:query})
        }
    }
}
</script>
<style scoped>
    .container-list{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .container-item{
        width: 375px;
        height: 500px;
        border-radius: 4px;
	    border: solid 1px #eeeeee;
        margin-top: 50px;
        cursor: pointer;
        background-color: #fff;
        position: relative;
        overflow: hidden;
    }
    .container-item:hover{
        box-shadow: 0px 0px 8px 0px 
            rgba(0, 0, 0, 0.2);
    }
    .container-item:hover > span{
        color: #d6ab7e;
    }
    .container-item > .img{
        width: 100%;
        height: 240px;
    }
    .container-item > .img img{
        width: 100%;
        height: 100%;
    }
    .container-item >span{
        display: -webkit-box;
        font-size: 24px;
        line-height: 38px;
        font-weight: bold;
        color: #333333;
        margin: 30px 30px 0 30px;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .container-item > P{
        font-size: 15px;
	    color: #999999;
        line-height: 28px;
        padding: 0 30px;
        text-overflow: ellipsis;
         display: -webkit-box;     
        -webkit-line-clamp: 2;     
        -webkit-box-orient: vertical;     
        overflow: hidden;
        margin-top: 30px;
    }
    .container-item >span:last-child{
        box-sizing: border-box;
        font-size: 14px;
        color: #666;
        font-weight: 500;
        position: absolute;
        bottom: 19px;
        left: 30px;
        margin: 0;
    }

    /* 移动端样式 */
    @media only screen and (max-width:1199px){
        .container-item{
            width:100%;
            margin-top: 25px;
            background-color: #f8f8f8;
            border-radius: 5px;
            overflow: hidden;
            height: auto;
        }
        .container-item:first-child{
            margin-top: 5px;
        }
        .container-item > .img{
            height: 175px;
        }
        .container-item >span{
            font-size: 16px;
            line-height:30px;
            margin:13px 15px 0;
        }
        .container-item > P{
            margin:8px 26px 0 15px;
            font-size: 14px;
            line-height: 24px;
            padding: 0;
            color:#666;
        }
        .container-item >span:last-child{
            font-size: 11px;
            line-height: 20px;
            margin:10px 0 0 15px;
            padding:0 0 16px 0;
            color:#999;
            position: static;
        }
    }
</style>