<template>
    <div class="index" v-loading="loading">
        <div class="typeArea">
            <z-title :textEn="`MENGHAI INFORMATION`" :text="`勐海资讯`" :color="`#867d70`"></z-title>
            <list-item :listData="informationData" :type="1"></list-item>
        </div>
        <div style="width: 100%;background-color:#f7f7f7">
            <div class="typeArea">
                <z-title :textEn="`PU'ER TEA NEWS`" :text="`普洱茶新闻`" :color="`#867d70`"></z-title>
                <list-item :bgColor="`#fff`" :listData="newsData" :type="2"></list-item>
            </div>
        </div>
        <div class="typeArea">
            <z-title :textEn="`GOVERNMENT POLICY`" :text="`政府政策`" :color="`#867d70`"></z-title>
            <list-item :listData="policyData" :type="3"></list-item>
        </div>
	</div>
</template>
<script>
import zTitle from "../../components/z-title.vue"
import listItem from "../../components/news-list-item.vue"
import {getCategory} from "../../api/api.js"
import apiConfig from "../../api/config"

export default {
    components:{zTitle,listItem},
    data(){
        return{
            configUrl:'',
            // 勐海资讯
            informationData : {},
            // 普洱茶新闻
            newsData : {},
            // 政府政策
            policyData : {},
            loading:true
        }
    },
    created(){
        this.getNewsList()
        this.configUrl = apiConfig.url
    },
    methods:{
        // 获取新闻列表
        getNewsList(){
            let params = {
                pageNum : 1,
                pageSize : 3
            }
            for(let i=1;i<=3;i++){
                getCategory(i,params,(res) => {
                    if(i == 1){
                        this.informationData = res.data
                    }else if(i == 2){
                        this.newsData = res.data
                    }else{
                        this.policyData = res.data
                    }
                    this.loading = false
                })
            }
            
        }
    }
    
}
</script>
<style scoped>
    .index .typeArea{
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }

    /* 移动端样式 */
    @media only screen and (max-width:1199px) {
        .index .typeArea{
            width:100%;
            padding:0;
        }
        .container-list{
            padding:0 26px 0 15px;
            box-sizing:border-box; 
        }
    }
</style>