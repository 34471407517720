<template>
    <div class="z-title" :style="{color:color}">
        <span v-if="screenState">{{textEn}}</span>
        <span class="text">{{text}}</span>
        <span v-if="!screenState">{{textEn}}</span>
    </div>
</template>
<script>
export default {
    data(){
        return{
            screenState : true
        }
    },
    props:{
        text:"",
        textEn : "",
        color :"",
    },
    created(){
		// 初始化获取浏览器窗口大小
		let screenWidth = document.body.clientWidth;
		// 根据浏览器窗口大小判断是否显示页面内的部分内容
		if(screenWidth<1200){
			this.screenState=false;
		}else{
			this.screenState=true;
		}
	}
}
</script>
<style>
    .z-title{
        width: 100%;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
        padding: 75px 0 30px;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
    }
    .text{
	    font-size: 36px;
	    font-weight: bold;
    }
    @media only screen and (max-width:1199px) {
        .z-title{
            align-items: center;
            font-size: 12px;
            padding: 30px 0 25px;
            border-bottom: none;
        }
        .text{
            font-size: 18px;
        }
    }
</style>